<template>
  <div>
    <c-step 
      :items="stepItems" 
      v-model="step">
    </c-step>
  </div>
</template>

<script>
export default {
  name: 'stepper',
  props: {
    popupParam: {
      type: Object,
      default: () => ({

      }),
    },
  },
  data() {
    return {
      editable: true,
      splitter: 5,
      step: 1,
      stepItems: [
        {
          name: 1,
          title: 'base page 1',
          icone: '',
          disable: false,
          message: '※ 요기는 단계에 대한 정보를 기입하는곳이예요. 기입해야 보여요.<br/>'
            + '※ 두번째 가보면 메시지 안적어서 안 보일거예요.<br/>'
            + '※ 세번째는 비활성화예요',
          component: () => import(`${'./basePage.vue'}`),
          param: {
            a: 1,
          }
        },
        {
          name: 2,
          title: 'base page 2',
          icone: '',
          disable: false,
          component: () => import(`${'./basePage.vue'}`),
          param: {
            a: 2,
          }
        },
        {
          name: 3,
          title: 'base page 3.... 요기는 비활성화!!',
          icone: '',
          disable: true,
          component: () => import(`${'./basePage.vue'}`),
          param: {
            a: 3,
          }
        },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>
