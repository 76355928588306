var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-step", {
        attrs: { items: _vm.stepItems },
        model: {
          value: _vm.step,
          callback: function ($$v) {
            _vm.step = $$v
          },
          expression: "step",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }